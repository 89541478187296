<template>
  <div>
    <VTitle :title="$t('app.usage')" class="section-title" />

    <TableCard
      display-pagination
      :headers="headers"
      :is-loading="isLoading"
      :rows="displayedRows"
      :pagination="pagination"
      @update:current_page="onUpdateCurrentPage"
    >
      <!-- eslint-disable-next-line -->
      <template #item.name="{ item }">
        <div class="cursor-pointer" @click="onRedirect(item)">
          {{ item.name }}
        </div>
      </template>
    </TableCard>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import useTraining from "@/composables/useTraining";
import useCollection from "@/composables/useCollection";
import usePaginate from "@/composables/usePaginate";
import useTexts from "@/composables/useTexts";
// Components
import TableCard from "@/components/tables/TableCard";
import VTitle from "@/components/VTitle";
// Constants
import usageTypes from "@/constants/usageTypes";

export default {
  components: {
    TableCard,
    VTitle
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    usageType: {
      type: String,
      required: true,
      validation: value => Object.values(usageTypes).includes(value)
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();
    const { t } = useI18n();

    // Data
    const { data, pagination, isLoading, read } = useReadMultiple();
    const { getText } = useTexts();
    const { route: collectionRoute } = useCollection();
    const { route: trainingRoute } = useTraining();

    // Computed
    const headers = computed(() => {
      return [
        {
          text: t("app.name"),
          value: "name"
        },
        {
          text: t("app.versions"),
          value: "version"
        }
      ];
    });

    const displayedRows = computed(() => {
      return (
        data?.value?.map(row => ({
          ...row,
          name: getText(row.texts, "name")
        })) ?? []
      );
    });

    // Methods
    const getData = async () => {
      await read({
        endpoint: `${props.endpoint}.usage`,
        pathParams: {
          id: props.id
        }
      });
    };

    // Methods
    const onRedirect = item => {
      if (props.usageType === usageTypes.TRAINING) {
        const id = item?.pivot?.collection_id;
        redirectToCollection(id);
        return;
      }

      const id = item?.pivot?.training_id;
      redirectToTraining(id);
    };

    const redirectToCollection = id => {
      router.push({
        name: `${collectionRoute}-update`,
        params: { id }
      });
    };

    const redirectToTraining = id => {
      router.push({
        name: `${trainingRoute}-update`,
        params: { id }
      });
    };

    const { onUpdateCurrentPage } = usePaginate(pagination, getData);

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      onRedirect,
      headers,
      displayedRows,
      // usePaginate
      onUpdateCurrentPage,
      // useReadMultiple
      pagination,
      rows: data,
      isLoading
    };
  }
};
</script>
